import { List } from 'immutable';
import {
  Booking,
  assertRelationIsObject,
  assertRelationIsListOfObject,
  Customer,
  BOOKING_ENTITY_NAME,
  assertRelationIsNullOrObject,
} from 'mapado-ticketing-js-sdk';
import { formatPrice, getShortId } from '@mapado/js-component';
import { AvailableSeatType } from '../../../propTypes';
import { TFunction } from '../../../i18n';

export function getBookingDisplayedLabel(
  booking: Booking,
  t: TFunction
): string | null {
  if (!booking.entityName) {
    // TODO@lpot [once booking fully migrated] Remove this condition and everything in it
    if (booking.cart) {
      assertRelationIsObject(booking.cart, 'booking.cart');

      return t('booking.number.booking', {
        cart: booking.cart.getShortId(),
      });
    }

    if (booking.order) {
      assertRelationIsObject(booking.order, 'booking.order');

      return t('booking.number.order', {
        order: booking.order.getShortId(),
      });
    }

    return null;
  }

  return (
    (booking.entityName === BOOKING_ENTITY_NAME.CART &&
      t('booking.number.booking', {
        cart: booking.entityId,
      })) ||
    (booking.entityName === BOOKING_ENTITY_NAME.ORDER &&
      t('booking.number.order', {
        order: booking.entityId,
      })) ||
    null
  );
}

type getBookingItemInfosProps = {
  seatLabel: string | null | undefined;
  price: string | null | undefined;
  participant: Customer | null | undefined;
};

export function getBookingItemInfos(
  booking: Booking,
  availableSeatList: List<AvailableSeatType> | null,
  seatInfoId?: string,
  seatLabel?: string | null
): getBookingItemInfosProps {
  if (!seatInfoId) {
    return {
      seatLabel: null,
      price: null,
      participant: null,
    };
  }

  // TODO@lpot [once booking fully migrated] Delete this check and everything in it
  if (!booking.bookingItemList.size) {
    const typeBooking = booking.order || booking.cart;

    const ticketId = (availableSeatList || List()).find((availableSeat) => {
      assertRelationIsObject(availableSeat.seat, 'availableSeat.seat');

      return seatInfoId === availableSeat.seat['@id'];
    })?.ticket;

    assertRelationIsObject(typeBooking, 'typeBooking');

    assertRelationIsListOfObject(
      typeBooking.ticketList,
      'typeBooking.ticketList'
    );

    const ticket = typeBooking.ticketList.find(
      (bookingTicket) => ticketId === bookingTicket['@id']
    );

    let participant = null;

    if (ticket && ticket.participant) {
      assertRelationIsObject(ticket, 'ticket');
      assertRelationIsNullOrObject(ticket.participant, 'ticket.participant');

      const participantFirstName = ticket.participant?.get('firstname');
      const participantLastName = ticket.participant?.get('lastname');

      // if ticket has participant info must be displayed instead of customer
      participant =
        participantFirstName || participantLastName
          ? ({
              crmId: ticket.participant?.get('crmId'),
              firstname: participantFirstName,
              lastname: participantLastName,
            } as Customer)
          : null;
    }

    const price =
      ticket &&
      `${ticket?.ticketPriceName} ${formatPrice(
        ticket?.facialValue ?? 0,
        ticket?.currency ?? ''
      )}`;

    return {
      seatLabel,
      price,
      participant,
    };
  }

  const selectedBookingItem = booking.bookingItemList.find(
    (item) => `${item.seatId}` === getShortId(seatInfoId)
  );

  return {
    seatLabel: selectedBookingItem?.seatLabel,
    price: `${selectedBookingItem?.ticketPriceName} ${formatPrice(
      selectedBookingItem?.ticketPriceFacialValue ?? 0,
      selectedBookingItem?.currency ?? ''
    )}`,
    participant: selectedBookingItem?.participant,
  };
}
