import { getEntityId, getShortId } from '@mapado/js-component';
import { List, Map, Set } from 'immutable';
import {
  assertRelationIsDefined,
  assertRelationIsObject,
  assertRelationIsString,
  Booking,
  Contingent,
  StockContingent,
} from 'mapado-ticketing-js-sdk';
import { createSelector } from 'reselect';
import { ShouldNotHappenError } from '../errors';
import {
  AvailableSeatModelType,
  AvailableSeatType,
  LogicalSeatConfigType,
  SeatConfigType,
  SeatGroupType,
  SeatType,
  EventDateType,
} from '../propTypes';
import type { RootState } from '../reducers';
import {
  useSeatingSelector,
  useSeatingStore,
} from '../reducers/typedFunctions';

export const eventDateSelector = (state: RootState): EventDateType | null =>
  state.seating.get('eventDate');

export const useEventDateSelector = (): EventDateType | null =>
  useSeatingSelector(eventDateSelector);

export const seatSelector = (state: RootState): List<SeatType> | null =>
  state.seating.get('seatList');

const stockContingentSelector = (
  state: RootState
): List<StockContingent> | null => state.seating.get('stockContingentList');

export const useStockContingentSelector = (): List<StockContingent> | null =>
  useSeatingSelector(stockContingentSelector);

export const contingentSelector = (state: RootState): List<Contingent> | null =>
  state.seating.get('contingentList');

export const selectedSeatIdSetSelector = (
  state: RootState
): Set<number> | null => state.seating.get('selectedSeatIdSet');

export const useSelectedSeatIdSetSelector = (): Set<number> | null =>
  useSeatingSelector(selectedSeatIdSetSelector);

export const availableSeatSelector = (
  state: RootState
): List<AvailableSeatType> | null => state.seating.get('availableSeatList');

export const useAvailableSeatSelector = (): List<AvailableSeatType> | null =>
  useSeatingSelector(availableSeatSelector);

export const availableSeatModelSelector = (
  state: RootState
): List<AvailableSeatModelType> | null =>
  state.seating.get('availableSeatModelList');

const seatGroupSelector = (state: RootState): List<SeatGroupType> | null =>
  state.seating.get('seatGroupList');

export const useSeatGroupSelector = (): List<SeatGroupType> | null =>
  useSeatingSelector(seatGroupSelector);

export const seatConfigSelector = (state: RootState): SeatConfigType | null =>
  state.seating.get('seatConfig');

const logicalSeatConfigSelector = (
  state: RootState
): LogicalSeatConfigType | null => state.seating.get('logicalSeatConfig');

export function findAvailableSeatForSeatId(
  state: RootState,
  seatLongId: string
): AvailableSeatType {
  const availableSeat = (
    availableSeatSelector(state) || List<AvailableSeatType>()
  ).find((avs) => {
    assertRelationIsObject(avs.seat, 'as.seat');

    const avsSeatId = avs.seat['@id'];

    assertRelationIsString(avsSeatId, 'avsSeatId');

    return avsSeatId === seatLongId;
  });

  if (!availableSeat) {
    throw new ShouldNotHappenError(
      `Unable to fetch available seat for seat id ${seatLongId}`
    );
  }

  return availableSeat;
}

export const getSelectedAvailableSeatList = createSelector(
  selectedSeatIdSetSelector,
  availableSeatSelector,
  (
    selectedSeatIdSet: Set<number> | null,
    availableSeatList: List<AvailableSeatType> | null
  ): List<AvailableSeatType> | null => {
    if (!availableSeatList) {
      return null;
    }

    return availableSeatList.filter((as) => {
      assertRelationIsObject(as.seat, 'as.seat');

      const availableSeatSeatShortId = as.seat
        ? Number(as.seat['@id'].replace('/v1/seats/', ''))
        : null;

      return !!(
        availableSeatSeatShortId &&
        selectedSeatIdSet?.has(availableSeatSeatShortId)
      );
    });
  }
);

export const useSelectedAvailableSeatList = (): List<AvailableSeatType> => {
  const store = useSeatingStore();

  return getSelectedAvailableSeatList(store.getState()) || List();
};

export const getAvailableSeatMappedBySeatId = createSelector(
  availableSeatSelector,
  (
    availableSeatList: List<AvailableSeatType> | null
  ): Map<string, AvailableSeatType> => {
    const map: Record<string, AvailableSeatType> = {};

    if (!availableSeatList) {
      return Map(map);
    }

    availableSeatList.forEach((as) => {
      if (as.seat) {
        assertRelationIsObject(as.seat, 'as.seat');

        const seatId = as.seat['@id'];

        map[seatId] = as;
      }
    });

    return Map(map);
  }
);

export const getAvailableSeatModelMappedBySeatId = createSelector(
  availableSeatModelSelector,
  (
    availableSeatModelList: List<AvailableSeatModelType> | null
  ): Map<string, AvailableSeatModelType> => {
    const map: Record<string, AvailableSeatModelType> = {};

    if (!availableSeatModelList) {
      return Map(map);
    }

    availableSeatModelList.forEach((avsm) => {
      if (avsm.seat) {
        assertRelationIsObject(avsm.seat, 'as.seat');

        map[avsm.seat['@id']] = avsm;
      }
    });

    return Map(map);
  }
);

const focusedBookingSelector = (state: RootState): null | Booking =>
  state.seating.get('focusedBooking');

export const useFocusedBooking = () =>
  useSeatingSelector(focusedBookingSelector);

export const isLoadedAvailableSeatList = createSelector(
  seatConfigSelector,
  availableSeatSelector,
  seatGroupSelector,
  seatSelector,
  (
    seatConfig: SeatConfigType | null,
    availableSeatList: List<AvailableSeatType> | null,
    seatGroupList: List<SeatGroupType> | null,
    seatList: List<SeatType> | null
  ): boolean => {
    return (
      seatConfig !== null &&
      availableSeatList !== null &&
      seatGroupList !== null &&
      seatList !== null
    );
  }
);

export const isLoadedEventDateWithData = createSelector(
  eventDateSelector,
  (eventDate: EventDateType | null): boolean => {
    return eventDate !== null;
  }
);

export const isLoadedSeatConfig = createSelector(
  seatConfigSelector,
  seatGroupSelector,
  seatSelector,
  (
    seatConfig: SeatConfigType | null,
    seatGroupList: List<SeatGroupType> | null,
    seatList: List<SeatType> | null
  ): boolean => {
    return seatConfig !== null && seatGroupList !== null && seatList !== null;
  }
);

export const isLoadedAvailableSeatModelList = createSelector(
  logicalSeatConfigSelector,
  seatGroupSelector,
  seatSelector,
  availableSeatModelSelector,
  (
    logicalSeatConfig: LogicalSeatConfigType | null,
    seatGroupList: List<SeatGroupType> | null,
    seatList: List<SeatType> | null,
    availableSeatModelList: List<AvailableSeatModelType> | null
  ): boolean => {
    return (
      logicalSeatConfig !== null &&
      seatGroupList !== null &&
      seatList !== null &&
      availableSeatModelList !== null
    );
  }
);

const getSeatGroupMap = createSelector(
  seatGroupSelector,
  (seatGroupList: List<SeatGroupType> | null): Map<string, SeatGroupType> => {
    if (!seatGroupList) {
      return Map<string, SeatGroupType>();
    }

    return seatGroupList.reduce((acc, seatGroup) => {
      assertRelationIsObject(seatGroup, 'seatGroup');

      return acc.set(seatGroup['@id'], seatGroup);
    }, Map<string, SeatGroupType>());
  }
);

export const getSeatGroupColorMap = createSelector(
  getSeatGroupMap,
  (seatGroupMap: Map<string, SeatGroupType>): Map<string, string | null> => {
    return seatGroupMap.map((seatGroup) => seatGroup.seatColor);
  }
);

export const getSeatGroupLabelMap = createSelector(
  getSeatGroupMap,
  (seatGroupMap: Map<string, SeatGroupType>): Map<string, string | null> => {
    return seatGroupMap.map((seatGroup) => seatGroup.label);
  }
);

export function getJsEntityId(jsEntity: string | { '@id': string }): string {
  return typeof jsEntity === 'string' ? jsEntity : jsEntity['@id'];
}

export const getAvailableSeatModelListBySeatGroupId = createSelector(
  availableSeatModelSelector,
  seatGroupSelector,
  (availableSeatModelList: List<AvailableSeatModelType> | null) => {
    if (!availableSeatModelList) {
      return null;
    }

    return availableSeatModelList.groupBy((avsm) => {
      assertRelationIsDefined(avsm.seatGroup, 'avsm.seatGroup');

      return getJsEntityId(avsm.seatGroup);
    });
  }
);

export const getAvailableSeatModelListByContingentId = createSelector(
  availableSeatModelSelector,
  (availableSeatModelList: List<AvailableSeatModelType> | null) => {
    if (!availableSeatModelList) {
      return null;
    }

    return availableSeatModelList.groupBy((avsm) => {
      return avsm.contingent ? getEntityId(avsm.contingent) : 'no-contingent';
    });
  }
);

export const getSelectedSeatIdListHash = createSelector(
  selectedSeatIdSetSelector,
  (selectedSeatIdSet: Set<number> | null) => {
    if (!selectedSeatIdSet) {
      return null;
    }

    return selectedSeatIdSet.join(',');
  }
);

export const getSelectedSeatInfo = createSelector(
  seatSelector,
  selectedSeatIdSetSelector,
  (seatList: List<SeatType> | null, selectedSeatIdSet: Set<number> | null) => {
    // Get seat data from seatList
    const seatData = (seatList || List()).find((seat) =>
      (selectedSeatIdSet || Set()).has(Number(getShortId(seat['@id'])))
    );

    return {
      seatLabel: seatData?.label ?? null,
      seatInfoId: seatData?.['@id'],
    };
  }
);
