import React, { ReactElement } from 'react';
import { Booking } from 'mapado-ticketing-js-sdk';
import {
  getSelectedSeatInfo,
  useAvailableSeatSelector,
} from '../../../utils/selectors';
import {
  getBookingDisplayedLabel,
  getBookingItemInfos,
} from './getBookingInfosHelper';
import CustomerLine from './CustomerLine';
import '../../../styles/components/Sidebar/BookingItemRecap.scss';
import { useSeatingStore } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

function BookingItemRecap({
  booking,
}: BookingItemRecapProps): ReactElement | null {
  const { t } = useTranslation();
  const store = useSeatingStore();
  const { seatInfoId, seatLabel } = getSelectedSeatInfo(store.getState()); // exist if selected from seating plan
  const availableSeatList = useAvailableSeatSelector();

  const bookingItemInfos = getBookingItemInfos(
    booking,
    availableSeatList,
    seatInfoId,
    seatLabel
  ); // TODO@lpot [once booking fully migrated] Remove seatInfo and availableSeatList params

  return (
    <div className="booking-item-recap">
      <div>
        {bookingItemInfos.seatLabel ? (
          <>
            <span className="booking-item-recap__seat">
              {bookingItemInfos.seatLabel}
            </span>
            {bookingItemInfos.price && ` : ${bookingItemInfos.price}`}
          </>
        ) : (
          getBookingDisplayedLabel(booking, t) // ex: Commande n° / Reservation n°
        )}
      </div>

      {booking.cart && typeof booking.cart !== 'string' && (
        <CustomerLine
          entity={booking.cart}
          participant={bookingItemInfos.participant}
        />
      )}
      {booking.order && typeof booking.order !== 'string' && (
        <CustomerLine
          entity={booking.order}
          participant={bookingItemInfos.participant}
        />
      )}
    </div>
  );
}

type BookingItemRecapProps = {
  booking: Booking;
};

export default BookingItemRecap;
