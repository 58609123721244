import { Booking, Order, Cart } from 'mapado-ticketing-js-sdk';
import type { AvailableSeatType } from '../propTypes';

export const BOOKING_STATUS_AVAILABLE = 'available';
export const BOOKING_STATUS_DISMISSED = 'dismissed';
export const BOOKING_STATUS_IN_ORDER = 'in_order';
export const BOOKING_STATUS_IN_CART = 'in_cart';
export const BOOKING_STATUS_IN_RESERVATION = 'in_reservation';
export const BOOKING_STATUS_SCANNED = 'scanned';
export const BOOKING_STATUS_IN_EXTERNAL_PROVIDER_ORDER =
  'in_external_provider_order';
export const BOOKING_STATUS_IN_EXTERNAL_PROVIDER_RESERVATION =
  'in_external_provider_reservation';

export type BOOKING_STATUS_LIST =
  | typeof BOOKING_STATUS_AVAILABLE
  | typeof BOOKING_STATUS_DISMISSED
  | typeof BOOKING_STATUS_IN_ORDER
  | typeof BOOKING_STATUS_IN_CART
  | typeof BOOKING_STATUS_IN_RESERVATION
  | typeof BOOKING_STATUS_SCANNED
  | typeof BOOKING_STATUS_IN_EXTERNAL_PROVIDER_ORDER
  | typeof BOOKING_STATUS_IN_EXTERNAL_PROVIDER_RESERVATION;

export function isCartEntity(seatElement: AvailableSeatType): boolean {
  return (
    seatElement.bookingStatus === BOOKING_STATUS_IN_RESERVATION ||
    seatElement.bookingStatus === BOOKING_STATUS_IN_CART
  );
}

export function isBooking(
  booking: undefined | null | Cart | Order | Booking
): booking is Booking {
  if (!booking) {
    return false;
  }

  return booking.get('@type', null) === 'Booking';
}

export function isCart(
  booking: undefined | null | Cart | Order | Booking
): booking is Cart {
  if (!booking) {
    return false;
  }

  return booking.get('@type', null) === 'Cart';
}

export function isOrder(
  booking: undefined | null | Cart | Order | Booking
): booking is Order {
  if (!booking) {
    return false;
  }

  return booking.get('@type', null) === 'Order';
}
