import React, { ReactElement } from 'react';
import { CustomerType } from 'mapado-ticketing-js-sdk';
import { getCustomerFullname, getShortId } from '@mapado/js-component';
import { SeatType } from '../../propTypes';
import { useTranslation } from '../../i18n';

type Props = {
  seat: SeatType;
  participant: CustomerType | null;
  isDesk: boolean;
};

function SeatInfoTooltip({
  seat,
  participant,
  isDesk,
}: Props): null | ReactElement {
  const { t } = useTranslation();
  const charMaxLength = 28;

  if (!seat.label) {
    return null;
  }

  const participanName = getCustomerFullname(participant);
  const participantTruncated =
    participanName && participanName.length > charMaxLength
      ? `${participanName?.slice(0, charMaxLength)}...`
      : participanName;

  return (
    <div>
      <div className="bold">
        {participanName && isDesk
          ? t('tooltip_participant', {
              seat: seat.label,
              participant: participantTruncated,
            })
          : seat.label}
      </div>
      <div className="seatId">(SeatId: {getShortId(seat['@id'])})</div>
    </div>
  );
}

export default SeatInfoTooltip;
