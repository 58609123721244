import { assertRelationIsObject, Order } from 'mapado-ticketing-js-sdk';
import { formatPrice } from '@mapado/js-component';
import React from 'react';
import CustomerCell from '../OrderManagement/Sidebar/BookingCustomerCell';
import { useTranslation } from '../../i18n';

function OrderTile({ order }: OrderTileType) {
  const { t } = useTranslation();

  assertRelationIsObject(order, 'order');

  const formattedPrice = formatPrice(
    order.retailValue ?? 0,
    order.currency ?? ''
  );

  return (
    <section className="mpd-seating-booking-tile__container">
      <div>
        <div className="txtcenter">
          <span>
            {t('sales.list_header.order', { number: order.getShortId() })}
          </span>
          <CustomerCell entity={order} />
        </div>

        <div className="mpd-seating-booking-tile__ticket_coupon_details txtcenter mt4">
          {formattedPrice !== null && (
            <header className="mpd-table__cell-header">{formattedPrice}</header>
          )}

          <article className="mpd-table__cell-description txtcenter mt4">
            {order.nbTickets !== 0 &&
              t('order.nb_tickets', {
                count: order.nbTickets ?? 0,
              })}
          </article>
        </div>

        {order.comment != null && (
          <div className="mpd-seating-booking-tile__comment">
            {t('order.comment')} {order.comment}
          </div>
        )}

        <div className="mpd-seating-booking-tile__origin">
          {t('sales.list_header.origin')}{' '}
          {t('order.origin', { name: order?.originType?.toLowerCase() })}
        </div>
      </div>
    </section>
  );
}

type OrderTileType = {
  order: Order;
};

export default OrderTile;
