/* eslint-disable import/prefer-default-export */
import {
  CART_TYPE,
  CART_STATUS,
  Cart,
  createEntity,
} from 'mapado-ticketing-js-sdk';

export function getCart(): Cart {
  // This has been taken from an API call in desk.
  const cart: Cart = createEntity({
    '@id': '/v1/carts/6951889',
    '@type': 'Cart',
    '@context': '/v1/contexts/Cart',
    comment: null,
    currency: 'EUR',
    // @ts-expect-error issue with constructor definition
    createdAt: '2022-07-26T07:20:07+00:00',
    // @ts-expect-error issue with constructor definition
    cartItemList: [
      {
        '@id': '/v1/cart_items/22507570',
        '@type': 'CartItem',
        data: [],
        quantity: 1,
        ticketPrice: {
          '@id': '/v1/ticket_prices/2255892',
          '@type': 'TicketPrice',
          facialValue: 0,
          name: 'Invitation',
          eventDate: {
            '@id': '/v1/event_dates/352518',
            '@type': 'EventDate',
            startDate: '2022-10-08T21:00:00+02:00',
            ticketing: {
              '@id': '/v1/ticketings/8982',
              '@type': 'Ticketing',
              timezone: 'Europe/Paris',
              slug: 'mapado-city/im-walking-on-iceland',
            },
            isReservableOnline: false,
          },
          currency: 'EUR',
        },
        stockContingent: null,
        availableSeatList: [
          {
            '@id': '/v1/available_seats/14312605',
            '@type': 'AvailableSeat',
            seat: {
              '@id': '/v1/seats/46829',
              '@type': 'Seat',
              label: 'I6',
              row: 'I',
              seatNumber: '6',
              coordinates: {
                Y: '15.0',
                X: '5.0',
                Z: '0.0',
              },
            },
            seatGroup: '/v1/seat_groups/12497',
          },
        ],
      },
      {
        '@id': '/v1/cart_items/22507571',
        '@type': 'CartItem',
        data: [],
        quantity: 1,
        ticketPrice: {
          '@id': '/v1/ticket_prices/2255892',
          '@type': 'TicketPrice',
          facialValue: 0,
          name: 'Invitation',
          eventDate: {
            '@id': '/v1/event_dates/352518',
            '@type': 'EventDate',
            startDate: '2022-10-08T21:00:00+02:00',
            ticketing: {
              '@id': '/v1/ticketings/8982',
              '@type': 'Ticketing',
              timezone: 'Europe/Paris',
              slug: 'mapado-city/im-walking-on-iceland',
            },
            isReservableOnline: false,
          },
          currency: 'EUR',
        },
        stockContingent: {
          '@id': '/v1/stock_contingents/2255892',
          '@type': 'StockContingent',
          name: 'Presse',
        },
        availableSeatList: [
          {
            '@id': '/v1/available_seats/14312612',
            '@type': 'AvailableSeat',
            seat: {
              '@id': '/v1/seats/46830',
              '@type': 'Seat',
              label: 'I8',
              row: 'I',
              seatNumber: '8',
              coordinates: {
                Y: '15.0',
                X: '6.0',
                Z: '0.0',
              },
            },
            seatGroup: '/v1/seat_groups/12497',
          },
        ],
      },
    ],
    originType: 'DESK',
    originId: null,
    customer: null,
    couponCodeList: null,
    couponList: null,
    // @ts-expect-error issue with constructor definition
    couponValidityMap: [],
    isReservation: false,
    type: CART_TYPE.CART,
    status: CART_STATUS.DRAFT,
    reservationName: null,
    sellingDevice: '/v1/selling_devices/409',
    customerPaidAmount: 0,
    facialValue: 0,
    facialFeeValue: null,
    customerPaidAmountWithoutOffer: 0,
    totalTicketNumber: 2,
    // @ts-expect-error issue with constructor definition
    contractList: [
      {
        '@id': '/v1/contracts/237',
        '@type': 'Contract',
      },
    ],
    // @ts-expect-error issue with constructor definition
    organizationalUnitList: [
      {
        '@id': '/v1/organizational_units/281',
        '@type': 'OrganizationalUnit',
        name: 'Démo Support Mapado',
        gdprOptinMessage:
          "J'accepte de recevoir une vidéo de hip-hop d'un danseur mystère de Lyon 4ème, toutes les semaines",
        publicContactEmail: 'compta@mapado.com',
      },
    ],
    // @ts-expect-error issue with constructor definition
    customerRequiredFields: [
      {
        contractId: 237,
        label: 'phone',
        description: null,
        slug: 'phone',
        type: 'phoneList',
        options: [],
        isDeletable: false,
        position: 1,
        inputType: 'tel',
        formType: 'tel',
        formIsMultiple: true,
        formOptions: [],
        searchType: 'tel',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'lastname',
        description: null,
        slug: 'lastname',
        type: 'string',
        options: {
          isHidden: {
            organization: false,
          },
        },
        isDeletable: false,
        position: 2,
        inputType: 'text',
        formType: 'text',
        formIsMultiple: false,
        formOptions: {
          isHidden: {
            organization: false,
          },
        },
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'email',
        description: null,
        slug: 'email',
        type: 'emailList',
        options: [],
        isDeletable: false,
        position: 3,
        inputType: 'email',
        formType: 'email',
        formIsMultiple: true,
        formOptions: [],
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'firstname',
        description: null,
        slug: 'firstname',
        type: 'string',
        options: {
          isHidden: {
            organization: false,
          },
        },
        isDeletable: false,
        position: 5,
        inputType: 'text',
        formType: 'text',
        formIsMultiple: false,
        formOptions: {
          isHidden: {
            organization: false,
          },
        },
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'zipcode',
        description: null,
        slug: 'zipcode',
        type: 'string',
        options: [],
        isDeletable: false,
        position: 6,
        inputType: 'text',
        formType: 'text',
        formIsMultiple: false,
        formOptions: [],
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'customerType',
        description: null,
        slug: 'customerType',
        type: 'string',
        options: {
          options: [
            {
              value: 'contact',
              labelKey: 'Contact',
              icon: 'user',
            },
            {
              value: 'organization',
              labelKey: 'Structure',
              icon: 'building',
            },
          ],
        },
        isDeletable: false,
        position: 7,
        inputType: 'buttonList',
        formType: 'buttonList',
        formIsMultiple: false,
        formOptions: {
          options: [
            {
              value: 'contact',
              labelKey: 'Contact',
              icon: 'user',
            },
            {
              value: 'organization',
              labelKey: 'Structure',
              icon: 'building',
            },
          ],
        },
        searchType: 'buttonList',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'organizationType',
        description: null,
        slug: 'organizationType',
        type: 'string',
        options: {
          inputProps: {
            allowCreate: true,
            multi: false,
          },
          isHidden: {
            contact: false,
            organization: false,
          },
          fetchSuggestions: true,
        },
        isDeletable: false,
        position: 8,
        inputType: 'reactSelect',
        formType: 'reactSelect',
        formIsMultiple: false,
        formOptions: {
          inputProps: {
            allowCreate: true,
            multi: false,
          },
          isHidden: {
            contact: false,
            organization: false,
          },
          fetchSuggestions: true,
        },
        searchType: 'reactSelect',
        searchOptions: {
          inputProps: {
            allowCreate: true,
            multi: false,
          },
          isHidden: {
            contact: true,
          },
          fetchSuggestions: true,
        },
        required: false,
      },
      {
        contractId: 237,
        label: 'city',
        description: null,
        slug: 'city',
        type: 'string',
        options: [],
        isDeletable: false,
        position: 9,
        inputType: 'text',
        formType: 'text',
        formIsMultiple: false,
        formOptions: [],
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'optinEmailInfo',
        description: null,
        slug: 'optinEmailInfo',
        type: 'boolean',
        options: {
          isHidden: {
            contact: false,
          },
        },
        isDeletable: false,
        position: 10,
        inputType: 'checkbox',
        formType: 'checkbox',
        formIsMultiple: false,
        formOptions: {
          isHidden: {
            contact: false,
          },
        },
        searchType: 'select',
        searchOptions: {
          options: [
            {
              value: 'true',
              labelKey: 'yes',
              default: false,
            },
            {
              value: 'false',
              labelKey: 'no',
              default: false,
            },
          ],
        },
        required: false,
      },
      {
        contractId: 237,
        label: 'organizationIdList',
        description: null,
        slug: 'organizationIdList',
        type: 'parentIdList',
        options: {
          customerType: 'organization',
          inputProps: {
            allowCreate: true,
            multi: false,
          },
        },
        isDeletable: false,
        position: 11,
        inputType: 'parentIdListSelect',
        formType: 'parentIdListSelect',
        formIsMultiple: true,
        formOptions: {
          customerType: 'organization',
          inputProps: {
            allowCreate: true,
            multi: false,
          },
        },
        searchType: 'parentIdListSelect',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'Couleur des yeux',
        description:
          'Merci de renseigner obligatoirement ici l’adresse à laquelle vous souhaitez faire livrer la box (prénom et nom du destinataire, adresse, code postal, ville)',
        slug: 'genericText2',
        type: 'string',
        options: {
          isHidden: {
            contact: false,
            organization: false,
          },
          showDescription: false,
          inputProps: {
            multi: true,
            allowCreate: false,
          },
          fetchSuggestions: false,
          options: [],
        },
        isDeletable: false,
        position: 13,
        inputType: 'reactSelect',
        formType: 'reactSelect',
        formIsMultiple: false,
        formOptions: {
          isHidden: {
            contact: false,
            organization: false,
          },
          showDescription: false,
          inputProps: {
            multi: true,
            allowCreate: false,
          },
          fetchSuggestions: false,
          options: [],
        },
        searchType: 'reactSelect',
        searchOptions: {
          isHidden: {
            contact: false,
            organization: false,
          },
          showDescription: false,
          inputProps: {
            multi: true,
            allowCreate: false,
          },
          fetchSuggestions: false,
          options: [],
        },
        required: false,
      },
      {
        contractId: 237,
        label: 'address',
        description: '64248',
        slug: 'address',
        type: 'string',
        options: {
          showDescription: true,
        },
        isDeletable: false,
        position: 14,
        inputType: 'textarea',
        formType: 'textarea',
        formIsMultiple: false,
        formOptions: {
          showDescription: true,
        },
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
      {
        contractId: 237,
        label: 'tag',
        description: null,
        slug: 'tagList',
        type: 'array',
        options: {
          inputProps: {
            placeholder: 'crm.add_tag',
            promptText: 'crm.create_tag',
          },
          fetchSuggestions: true,
        },
        isDeletable: false,
        position: 18,
        inputType: 'reactSelect',
        formType: 'reactSelect',
        formIsMultiple: false,
        formOptions: {
          inputProps: {
            placeholder: 'crm.add_tag',
            promptText: 'crm.create_tag',
          },
          fetchSuggestions: true,
        },
        searchType: 'reactSelect',
        searchOptions: {
          inputProps: {
            multi: false,
            promptText: 'crm.create_tag',
            placeholder: 'crm.add_tag',
          },
          fetchSuggestions: true,
        },
        required: false,
      },
      {
        contractId: 237,
        label: 'comment',
        description: null,
        slug: 'comment',
        type: 'string',
        options: [],
        isDeletable: false,
        position: 19,
        inputType: 'textarea',
        formType: 'textarea',
        formIsMultiple: false,
        formOptions: [],
        searchType: 'text',
        searchOptions: [],
        required: true,
      },
      {
        contractId: 237,
        label: 'Détails de la structure',
        description: null,
        slug: 'genericText8',
        type: 'string',
        options: {
          isHidden: {
            contact: true,
          },
        },
        isDeletable: false,
        position: 20,
        inputType: 'text',
        formType: 'text',
        formIsMultiple: false,
        formOptions: {
          isHidden: {
            contact: true,
          },
        },
        searchType: 'text',
        searchOptions: [],
        required: false,
      },
    ],
    // @ts-expect-error issue with constructor definition
    walletList: [
      {
        '@id': '/v1/wallets/203',
        '@type': 'Wallet',
        provider: 'payline',
        providerContractNumber: '5649869',
      },
    ],
    // @ts-expect-error issue with constructor definition
    cartItemWithOfferList: [
      {
        cartItem: {
          '@id': '/v1/cart_items/22507570',
          '@type': 'CartItem',
          data: [],
          quantity: 1,
          ticketPrice: '/v1/ticket_prices/2255892',
          stockContingent: null,
        },
        availableSeatList: [
          {
            '@id': '/v1/available_seats/14312605',
            '@type': 'AvailableSeat',
            seat: {
              '@id': '/v1/seats/46829',
              '@type': 'Seat',
              label: 'I6',
              row: 'I',
              seatNumber: '6',
              coordinates: {
                Y: '15.0',
                X: '5.0',
                Z: '0.0',
              },
            },
            manuallyAssigned: true,
          },
          {
            '@id': '/v1/available_seats/14312612',
            '@type': 'AvailableSeat',
            seat: {
              '@id': '/v1/seats/46830',
              '@type': 'Seat',
              label: 'I8',
              row: 'I',
              seatNumber: '8',
              coordinates: {
                Y: '15.0',
                X: '6.0',
                Z: '0.0',
              },
            },
            manuallyAssigned: true,
          },
        ],
        ticketingTitle: "I'm Walking on Iceland",
        ticketingTimezone: 'Europe/Paris',
        date: '2022-10-08T21:00:00+02:00',
        endDate: null,
        name: 'Invitation',
        ticketPrice: {
          '@id': '/v1/ticket_prices/2255892',
          '@type': 'TicketPrice',
          facialValue: 0,
          type: 'exempted',
          name: 'Invitation',
          description: '',
          eventDate: {
            '@id': '/v1/event_dates/352518',
            '@type': 'EventDate',
            unlimitedStock: true,
            startDate: '2022-10-08T21:00:00+02:00',
            endDate: null,
            ticketing: {
              '@id': '/v1/ticketings/8982',
              '@type': 'Ticketing',
              timezone: 'Europe/Paris',
              title: "I'm Walking on Iceland",
              venue: {
                '@id': '/v1/venues/342',
                '@type': 'Venue',
                seatingName: null,
              },
              slug: 'mapado-city/im-walking-on-iceland',
              mediaList: [
                {
                  path: '2022/1/18/61e6b7e7b26d8-5bab8ca4c219c-dsc-0491.jpeg',
                  height: 2657,
                  width: 4000,
                  imageType: 'image',
                },
              ],
              imageList: [
                '2022/1/18/61e6b7e7b26d8-5bab8ca4c219c-dsc-0491.jpeg',
              ],
              currency: 'EUR',
            },
            seatConfig: '/v1/seat_configs/58',
            bookableStock: 197,
            notInStockContingentBookableStock: 147,
            notOnSaleReasons: [],
            remainingStock: 197,
          },
          hash: 'invitation|0',
          isPriceDisplayed: true,
          hideFees: true,
          currency: 'EUR',
        },
        quantity: 2,
        facialValue: 0,
        clusterId: null,
        applicationId: null,
        groupKey: null,
        valid: true,
        facialValueWithoutOffer: 0,
        offer: null,
        buyable: false,
      },
    ],
  }) as unknown as Cart;

  return cart;
}
