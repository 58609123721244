/**
 * Return a string with extra `0` on the left if the string length if inferior to `len` parameter`
 */
function padZero(str: string, len = 2): string {
  const zeros = new Array(len).join('0');

  return (zeros + str).slice(-len);
}

/**
 * https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
 * Find the inverse of a given color.
 * If the second option ("bw") is true, it will instead decide to invert to black or white (for contract)
 * @param {string} hex the hex color to invert
 * @param {boolean} bw  true if we want only invert to black or white
 * @return {string} the hex color of the inverted color
 */
export function invertColor(hex: string, bw: boolean): string {
  let hex2 = hex;

  if (hex2.indexOf('#') === 0) {
    hex2 = hex2.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (hex2.length === 3) {
    hex2 = hex2[0] + hex2[0] + hex2[1] + hex2[1] + hex2[2] + hex2[2];
  }

  if (hex2.length !== 6) {
    throw new Error(`Invalid HEX color. "${hex2}"`);
  }

  const r = parseInt(hex2.slice(0, 2), 16);
  const g = parseInt(hex2.slice(2, 4), 16);
  const b = parseInt(hex2.slice(4, 6), 16);

  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }

  // invert color components
  const r16 = (255 - r).toString(16);
  const g16 = (255 - g).toString(16);
  const b16 = (255 - b).toString(16);

  // pad each with zeros and return
  return `#${padZero(r16, 2)}${padZero(g16, 2)}${padZero(b16, 2)}`;
}

export function required<T>(item: null | T): T {
  if (!item) {
    throw new Error('Item should be defined. This should not happen.');
  }

  return item;
}
